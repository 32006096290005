<template>
  <modal
    name="bannerEdit"
    height="auto"
    width="900"
    :lazy="true"
    :scrollable="true"
    :clickToClose="false"
  >
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="modalTitle">
            <div class="form">
              <div class="alert remover" v-show="!!errorMessage">
                <a href="#" class="close" @click="errorMessage = null"
                  >&times;</a
                >
                Falha ao Salvar alterações:<br />
                <p>{{ errorMessage }}</p>
              </div>
              <div class="xrow">
                <div class="col-1-2">
                  <div class="form-group">
                    <label>Nome</label>
                    <input
                      class="form-control"
                      v-model="item.nome"
                      maxlength="1000"
                    />
                    <div class="message">
                      {{ validation.firstError("item.nome") }}
                    </div>
                  </div>
                </div>
                <div class="col-1-4">
                  <div class="form-group">
                    <label>Local de exibição</label>
                    <select
                      class="form-control"
                      v-model="item.local"
                      v-on:change="cleanUnusedFields"
                    >
                      <option v-for="local in locais" :value="local.value">
                        {{ local.desc }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-1-4" v-show="inputShouldBeVisible('popup')">
                  <div class="form-group">
                    <label>Abrir em pop-up</label>
                    <select class="form-control" v-model="item.popup">
                      <option :value="false">Não</option>
                      <option :value="true">Sim</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-4" v-show="inputShouldBeVisible('tipoMidia')">
                  <div class="form-group">
                    <label>Tipo de mídia</label>
                    <select class="form-control" v-model="item.tipo">
                      <option v-for="tipo in tipos" :value="tipo.value">
                        {{ tipo.desc }}
                      </option>
                    </select>
                    <div class="message">
                      {{ validation.firstError("item.tipo") }}
                    </div>
                  </div>
                </div>
                <div
                  class="col-1-2"
                  v-show="inputShouldBeVisible('url')"
                  v-if="item.tipo !== 'LINK_INTEGRADO'"
                >
                  <div class="form-group">
                    <label>{{ tipoLabel }}</label>
                    <input
                      class="form-control"
                      v-model="item.href"
                      maxlength="1000"
                    />
                  </div>
                </div>
                <!-- SE FOR LINK INTEGRADO, EXIBE UM SELECT PARA SELECIONAR O LINK DO ARRAY LINKSINTEGRADOS -->
                <div class="col-1-2" v-if="item.tipo === 'LINK_INTEGRADO'">
                  <div class="form-group">
                    <label>{{ tipoLabel }}</label>
                    <select
                      class="form-control"
                      v-model="item.link_integrado_id"
                    >
                      <option
                        v-for="link in linksIntegrados"
                        :value="link.id"
                      >
                        {{ link.descricao }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-1-4" v-show="inputShouldBeVisible('novaAba')">
                  <div class="form-group">
                    <label>Abrir em nova aba</label>
                    <select class="form-control" v-model="item.nova_aba">
                      <option :value="false">Não</option>
                      <option :value="true">Sim</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-4">
                  <div class="form-group">
                    <label>Data inicial de exibição</label>
                    <datetime
                      input-class="form-control"
                      type="date"
                      v-model="item.data_inicio"
                      :max-datetime="item.data_fim"
                      :phrases="{ ok: 'Confirmar', cancel: 'Fechar' }"
                      value-zone="local"
                      format="d/M/y"
                    />
                  </div>
                  <div
                    class="form-group"
                    v-show="inputShouldBeVisible('ordem')"
                  >
                    <label>Ordem de exibição</label>
                    <input
                      class="form-control"
                      v-model="item.ordem"
                      maxlength="2"
                    />
                  </div>
                </div>
                <div class="col-1-4">
                  <div class="form-group">
                    <label>Data final de exibição</label>
                    <datetime
                      input-class="form-control"
                      type="date"
                      :min-datetime="item.data_inicio"
                      v-model="item.data_fim"
                      :phrases="{ ok: 'Confirmar', cancel: 'Fechar' }"
                      value-zone="local"
                      format="d/M/y"
                    />
                  </div>
                </div>
                <div
                  :class="
                    this.item.local === 'PDF' ||
                    this.item.local === 'PDF_RODAPE'
                      ? 'col-1-2'
                      : 'col-1-4'
                  "
                >
                  <div class="form-group">
                    <label>Filiais de exibição</label>
                    <label v-for="filial in filiais">
                      <input
                        type="checkbox"
                        v-model="item.filiais"
                        :value="filial.id"
                      />
                      {{ filial.razSoc }}
                    </label>
                    <div class="message">
                      {{ validation.firstError("item.filiais") }}
                    </div>
                  </div>
                </div>
                <div class="col-1-4" v-show="inputShouldBeVisible('origem')">
                  <div class="form-group">
                    <label>Sistema Exibição</label>
                    <label v-for="sistema in sistemas">
                      <input
                        type="checkbox"
                        v-model="item.sistemas"
                        :value="sistema.id"
                      />
                      {{ sistema.descricao }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="xrow" style="min-height: 200px">
                <div class="col-1-2">
                  <image-picker
                    :label="tamanhoBannerDesktop"
                    placeholder="Colar URL ou clique ao lado para upload"
                    v-model="item.imagem_desktop"
                  />
                  <div class="form-group">
                    <div class="message">
                      {{ validation.firstError("item.imagem_desktop") }}
                    </div>
                  </div>
                </div>
                <div
                  class="col-1-2"
                  v-show="inputShouldBeVisible('imagemMobile')"
                >
                  <image-picker
                    :label="tamanhoBannerMobile"
                    placeholder="Colar URL ou clique ao lado para upload"
                    v-model="item.imagem_mobile"
                  />
                  <div class="form-group">
                    <div class="message">
                      {{ validation.firstError("item.imagem_mobile") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                :class="{
                  'button button-success': true,
                  'button-disabled': validation.hasError(),
                }"
                v-on:click="handleSubmit()"
              >
                Salvar Alterações
              </button>
              <button type="button" class="button" v-on:click="close">
                Fechar
              </button>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import ImagePicker from "../../components/ImagePicker";
import { tipos, locais } from "./helpers/constants";
import moment from "moment";
import _keyBy from "lodash/keyBy";
import CloseModal from "../../components/CloseModal";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "Campo de preenchimento obrigatório",
  },
});

export default {
  name: "bannerEdit",
  components: {
    ImagePicker,
    CloseModal,
  },
  props: [
    "onClose",
    "onSubmit",
    "item",
    "close",
    "filiais",
    "sistemas",
    "linksIntegrados",
  ],
  data() {
    return {
      tipos: tipos,
      locais: locais,
      errorMessage: null,
    };
  },
  validators: {
    "item.nome": (value) => Validator.value(value).required(),
    "item.tipo": (value) => Validator.value(value).required(),
    "item.imagem_desktop": (value) => Validator.value(value).required(),
  },
  watch: {
    "item.tipo"(value) {
      if (value) {
        this.item.nova_aba = this.tiposAba[value].aba;
      }
    },
  },
  computed: {
    tiposAba() {
      return _keyBy(this.tipos, "value");
    },
    tipoLabel() {
      return this.item.tipo
        ? this.tiposAba[this.item.tipo].label
        : "URL Destino";
    },
    modalTitle() {
      if (this.item.id) {
        return "Editar Banner";
      }
      return "Criar Banner";
    },
    tamanhoBannerDesktop() {
      switch (this.item.local) {
        case "PDF":
          return "Imagem desktop (Recomendado 800 x 150 px)";

        case "PDF_RODAPE":
          return "Imagem desktop (Recomendado 1387 x 55 px)";

        case "SUGESTAO":
          return "Imagem desktop (Recomendado 1500 x 300 px)";

        case "CASHBACK_CHECKOUT":
          return "Imagem desktop (Recomendado 720 x 800 px)";
      }

      return "Imagem desktop (Recomendado 1500 x 500 px)";
    },
    tamanhoBannerMobile() {
      switch (this.item.local) {
        case "CASHBACK_CHECKOUT":
          return "Imagem desktop (Recomendado 720 x 800 px)";
      }

      return "Imagem mobile (Recomendado 500 x 300 px)";
    },
  },
  methods: {
    normalizeItem(item) {
      item.data_inicio = item.data_inicio
        ? moment(item.data_inicio).format("YYYY-MM-DD")
        : null;
      item.data_fim = item.data_fim
        ? moment(item.data_fim).format("YYYY-MM-DD")
        : null;
      return item;
    },
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.onSubmit(this.normalizeItem(this.item)).catch((error) => {
            this.errorMessage = error.message;
          });
        }
      });
    },
    inputShouldBeVisible(inputName) {
      if (inputName === "imagemMobile" || inputName === "origem") {
        return (
          this.item.local === "HOME" ||
          this.item.local === "SUGESTAO" ||
          this.item.local === "CASHBACK_CHECKOUT"
        );
      }
      return this.item.local === "HOME";
    },
    cleanUnusedFields() {
      this.item.href = null;
      this.item.imagem_mobile = null;
    },
  },
};
</script>
