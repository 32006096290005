export const tipos = [
  {
    value: "LINK_EXTERNO",
    desc: "Link Externo",
    aba: true,
    label: "URL Destino",
  },
  {
    value: "VIDEO_YOUTUBE",
    desc: "Vídeo Youtube",
    aba: false,
    label: "Código do vídeo",
  },
  {
    value: "LINK_INTEGRADO",
    desc: "Link Integrado",
    aba: false,
    label: "Link Integrado",
  },
];

export const locais = [
  {
    value: "HOME",
    desc: "Home",
  },
  {
    value: "PDF",
    desc: "PDF cabeçalho orçamento/pedido",
  },
  {
    value: "PDF_RODAPE",
    desc: "PDF rodapé orçamento/pedido",
  },
  {
    value: "SUGESTAO",
    desc: "Sugestão pedido",
  },
  {
    value: "CASHBACK_CHECKOUT",
    desc: "Cashback checkout",
  },
];
