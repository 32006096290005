<template>
  <div>
    <Edit
      :item="currentItem"
      :close="closeEdit"
      :onSubmit="onSubmit"
      :filiais="filiais"
      :sistemas="sistemas"
      :linksIntegrados="linksIntegrados"
    />
    <div class="filters row" style="margin-top: 0">
      <div class="col-1-3">
        <!--<span class="filters__search">-->
        <!--<input type="text" name="" value="" placeholder="Filtrar por nome">-->
        <!--<fa-icon icon="search"/>-->
        <!--</span>-->
      </div>
      <div class="col-2-3">
        <div class="pull-right">
          <button
            v-on:click="openCreate"
            class="button button-success button-block"
          >
            <fa-icon icon="plus" />
            Criar
          </button>
        </div>
      </div>
    </div>

    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab title="BANNERS">
        <div class="table-responsive">
          <datatable :columns="table.columns" :data="items" class="table table-hover">
            <template slot-scope="{ row }">
              <tr>
                <td>{{ row.id }}</td>
                <td>
                  <img
                    v-if="row.imagem_desktop"
                    :src="row.imagem_desktop"
                    class="thumbnail thumbnail-small"
                  />
                </td>
                <td>
                  <img
                    v-if="row.imagem_mobile"
                    :src="row.imagem_mobile"
                    class="thumbnail thumbnail-small"
                  />
                </td>
                <td>{{ row.nome }}</td>
                <td>{{ row.data_inicio | date }}</td>
                <td>{{ row.data_fim | date }}</td>
                <td>{{ row.ordem }}</td>
                <td>{{ row.local }}</td>
                <td class="actions">
                  <button
                    type="button"
                    class="button button-primary"
                    v-on:click="openEdit(row)"
                  >
                    <fa-icon icon="pencil-alt" />
                  </button>
                  <button
                    type="button"
                    name="button"
                    v-on:click="deleteConfirm(row.id)"
                    class="button button-error"
                  >
                    <fa-icon icon="trash-alt" />
                  </button>
                </td>
              </tr>
            </template>
          </datatable>
        </div>
        <div style="justify-content: center; display: flex">
          <pagination
              :data="table.paginator"
              :limit="5"
              @pagination-change-page="loadBanners"
          ></pagination>
        </div>
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import clone from "just-clone";
import { get, post, put, del } from "@/helpers/apiRequest";
import Edit from "./Edit";

const initialItem = {
  id: null,
  nome: "",
  imagem_desktop: "",
  imagem_mobile: "",
  data_inicio: "",
  data_fim: "",
  tipo: "LINK_EXTERNO",
  nova_aba: true,
  popup: false,
  ordem: "1",
  local: "HOME",
  filiais: [],
  sistemas: [],
  link_integrado_id: null,
};

export default {
  name: "banners",
  components: {
    Edit,
  },
  props: ["sistemas", "linksIntegrados"],
  data() {
    return {
      currentItem: {},
      loading: false,
      error: false,
      items: [],
      term: "",
      filiais: [],
      table: {
        filter: "",
        columns: [
          { label: "ID", field: "id", align: "center", sortable: false },
          { label: "Desktop", field: "imagem_desktop", sortable: false },
          { label: "Mobile", field: "imagem_mobile", sortable: false },
          { label: "Nome", field: "nome", sortable: false },
          { label: "Início", field: "data_inicio", sortable: false },
          { label: "Fim", field: "data_final", sortable: false },
          { label: "Ordem", field: "ordem", sortable: false },
          { label: "Local", field: "local", sortable: false },
          { label: "", field: "", sortable: false },
        ],
        paginator: {},
      },
    };
  },
  methods: {
    loadLinksIntegrados() {
      get(`/admin/marketing/links-integracao`)
        .then((json) => {
          this.linksIntegrados = json;
        })
        .catch((err) => {
        });
    },
    loadBanners(page) {
      this.loading = true;
      get(`/admin/marketing/banners?page=${page}`)
        .then((json) => {
          this.items = json.itens;
          this.table.paginator = json.paginator;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    openEdit(item) {
      this.currentItem = clone(item);
      this.$modal.show("bannerEdit");
    },
    openCreate() {
      this.currentItem = clone(initialItem);
      this.$modal.show("bannerEdit");
    },
    closeEdit() {
      this.currentItem = clone(initialItem);
      this.$modal.hide("bannerEdit");
    },
    deleteConfirm(id) {
      this.$swal({
        title: "Confirma Remover?",
        text: "Essa ação não poderá ser desfeita",
        showCancelButton: true,
        icon: "warning",
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/marketing/banners/${id}`)
              .then((json) => {
                this.loading = false;
                this.loadBanners(this.table.paginator.current_page);
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: "Falha ao remover",
                  icon: "error",
                });
              });
          }
        })
        .catch(() => {});
    },
    onSubmit(item) {
      this.loading = true;
      const request = this.request(item);
      request
        .then((json) => {
          this.closeEdit();
          this.loadBanners();
          this.$swal({
            title: "Salvo com sucesso",
            text: json.message,
            showCancelButton: false,
            cancelButtonText: "Fechar",
            showConfirmButton: true,
            icon: "success",
          });
        })
        .catch((error) => {
          this.$swal({
            title: "Falha ao Salvar",
            text: error.message,
            showCancelButton: true,
            cancelButtonText: "Fechar",
            showConfirmButton: false,
            icon: "error",
          });
        });
      return request;
    },
    request(item) {
      if (item.id) {
        return put(`/admin/marketing/banners/${item.id}`, item);
      }
      return post(`/admin/marketing/banners`, item);
    },
  },
  beforeMount() {
    //Sempre uma função com contato na api antes de tudo, a apiRequest válida o token do usuário.
    this.loadBanners(1);
    this.loadLinksIntegrados(1);
    const { filiais } = JSON.parse(localStorage.getItem("app"));
    this.filiais = filiais;
  },
};
</script>